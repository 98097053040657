<template>
    <div class="teaHome">
        <div class="staticData">
            <headLine msg='企业数据统计' :height='height'></headLine>
            <ul>
                <li v-for="(v,i) in companyData" :key="i">
                   <div class="img">
                        <img :src="require('../assets/images/'+v.imgUrl+'.png')"/>
                    </div>
                    <div class="info">
                        <h3>{{v.count}}</h3>
                        <span>{{v.name}}</span>
                    </div> 
                </li>
            </ul>
        </div> 
        <div class="bottom">
            <div class="left">
                <headLine msg='企业行业分布' :height='height'></headLine>
                <div id='companyTrade' class="companyTrade"></div>
            </div>
            <div class="right">
                <headLine msg='企业城市分布' :height='height'></headLine>
                <div id='companyCity' class="companyCity"></div>
            </div>
        </div>
        <div class="teaBox">
            <headLine msg='招聘老师对接企业数量统计图' :height='height'></headLine>
            <div class="teaData" id="teaNum"></div>
        </div>
    </div>
</template>
<script>
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts');
// 引入柱状图
require('echarts/lib/chart/bar');
require('echarts/lib/chart/pie') ;
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
import {canvasJs} from '../service/canvas.js'
export default {
    data() {
        return {
            height:false,
            companyData:[
                {
                    name:"企业总数",
                    count:0,
                    key:'countNum',
                    imgUrl:"qu1"
                },
                {
                    name:"当日新增企业",
                    count:0,
                    key:'dayNum',
                    imgUrl:"qu2"
                },
                {
                    name:"本周新增企业",
                    count:0,
                    key:'weekNum',
                    imgUrl:"qu3"
                },
                {
                    name:"本月新增企业",
                    count:0,
                    key:'monthNum',
                    imgUrl:"qu4"
                },
            ]
        }
    },
    methods: {
        //求和
        sum(arr){
            return arr.reduce(function(prev, curr){
                return prev + curr;
            });
        },
        initeCharts(data){
            //企业行业分布柱状图
             let {companyTradeMap,companyCityMap}=data
             let sericeData=[],yAxisData=[],total=[]
             for(let i in companyTradeMap){
                 yAxisData.push(i)
                 total.push(companyTradeMap[i]) 
             }
             let sumNum=this.sum(total)
             total.forEach(e=>{
                 sericeData.push((e/sumNum*100).toFixed(0)*1)
             })
             
            let myChart1 = echarts.init(document.getElementById('companyTrade'));
            let options1=canvasJs.jobBar(yAxisData,sericeData,1)
            myChart1.setOption(options1)

            //企业城市分布柱状图
            let sericeData2=[],legendData2=[]
            for(let i in companyCityMap){
                legendData2.push(i)
                sericeData2.push({value:companyCityMap[i],name:i})
            }

            let myChart2 = echarts.init(document.getElementById('companyCity'));
            let options2=canvasJs.companyCityPie(legendData2,sericeData2)
            myChart2.setOption(options2)

            //根据窗口的大小变动图表 --- 重点 //若有多个图表变动，可多写
            window.onresize = function(){
                myChart1.resize();
                myChart2.resize();
            }
        },
        getInitData(){
            this.$htp.get_(`/tea/company/num/statistics`).then((res)=>{    
                 let {companyNum}=res.data
                 this.initeCharts(res.data)
                 this.companyData.forEach(e=>{
                    for(let i in companyNum){
                        if(i==e.key){
                            e.count=companyNum[i]
                            return false
                        }
                    }
                 })
                  
            }) 
        },
        getTeaInfoData(){
            this.$htp.get_('/tea/link/statistics').then(({data})=>{
                
                let xData =[],yData=[]
                data.forEach(e=>{
                    xData.push(Object.keys(e)[0])
                    yData.push(Object.values(e)[0])
                })
                let myChart = echarts.init(document.getElementById('teaNum'));
                let options=canvasJs.teaNumBar(xData,yData)
                myChart.setOption(options)
            })
        }
    },
    mounted(){
        this.getInitData()
        this.getTeaInfoData()
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/teahome.scss';
.teaBox{
    margin-top: 20px;
    div.teaData{
        margin-top: 10px;
        width: 100%;
        height: 300px;
         box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    }
}
</style>